<script setup lang="ts">
</script>

<template>
  <main p="x4 y10" text="center dark:gray-200" flex="~ col" h-screen place-content-center items-center bg-blue-950>
    <div i-mdi-map-marker-question inline-block h-30 w-30 />
    <h1 my-10>
      Vous vous êtes perdu(e) ?
    </h1>
    <p>
      La page que vous cherchez n'existe pas. <br>
      Veuillez cliquer sur le lien que vous avez reçu par email pour réserver votre créneau.
    </p>
  </main>
</template>
